<template>
  <div class="p-5 flex flex-column dashboard-help-table full-width">
     <content-card-full>
       <template #title>
         <card-title>
           <template #title><h3>CLARITY NEWS & VIEWS</h3></template>
           <template #content>
               <div style="min-width: 55px">
                  <a :href="clarityURL+'/newsviews'" target="_blank"><span class="small-text grey">View All</span></a>
               </div>
           </template>
         </card-title>
       </template>
       <template #content-full>
          <div class="scrollable">
            <div v-if="loading">
              <Skeleton class="mb-2" height="2rem" width="30rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-2" height="1.5rem" width="20rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-2" height="5rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-5" height="1.5rem" width="8rem" borderRadius="16px"></Skeleton>

              <Skeleton class="mb-2" height="2rem" width="30rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-2" height="1.5rem" width="20rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-2" height="5rem" borderRadius="16px"></Skeleton>
              <Skeleton class="mb-2" height="1.5rem" width="8rem" borderRadius="16px"></Skeleton>
            </div>
            <div v-else>
              <div v-for="article in articles" :key="article.id">
                <div class="mt-3">
                  <a :href="clarityURL+'/newsarticle?id=' + article.id" target="_blank"><h4 class="light-blue">{{ article.title.toUpperCase() }}</h4></a>
                </div>
                <div class="mt-3 flex flex-wrap align-items-center" style="row-gap: 10px;">
                  <div class="flex mr-2" v-for="tag in article.tags.split(',')" :key="tag">
                    <pill :label="tag" class="flex"></pill>
                  </div>
                  <div class="extra-small-text grey flex">By clarity | Published {{ article.date_published }}</div>
                </div>
                <div class="mt-3">
                  <span class="medium-body-text">{{ article.summary }}</span>
                </div>
                <div class="mt-3 mb-5">
                  <a :href="clarityURL+'/newsarticle?id=' + article.id" target="_blank"><span class="medium-body-text readmore">Read More</span></a>
                </div>
              </div>
            </div>
          </div>
       </template>
     </content-card-full>
  </div>
</template>
<script>

import ContentCardFull from "../common/ContentCardFull";
import CardTitle from "../common/CardTitle";
import {onMounted, ref} from "vue";
import Pill from "../nav/Pill";
import api from '@/core/services/api';
import {toClarityAnonRequest} from "../../core/helpers";

export default {
  name: "DashboardNews",
  components: {Pill, CardTitle, ContentCardFull},
  setup() {
    const clarityURL = process.env.VUE_APP_CLARITYURL;

    const error = false;
    const loading = ref(true)
    const articles = ref([]);

    // on mount ensure the data feeds call is made!
    onMounted(async () => {

      const newsEndpoint = '/news/?limit=5&start=0';
      const response = await api.get(`${toClarityAnonRequest(newsEndpoint)}`);
      if (response) {
        articles.value = response;
        loading.value = false;
      } else {
        // failed here
        loading.value = false;
        error.value = true;
      }

    })

    return {
      loading,
      articles,
      clarityURL
    }
  }
}
</script>
<style scoped lang="scss">
  h3 {
    color: var(--clarity-blue);
  }
  .scrollable {
    overflow-y:scroll;
    max-height:290px;
    overflow-x: hidden;
    padding-right: 8px;
  }
  .readmore {
    color: var(--clarity-light-blue);
    &:hover {
      color: var(--clarity-blue);
    }
  }
  .small-text:hover {
    color: var(--clarity-blue);
  }
</style>
