<template>
   <div class="dashboard-valuation-table">
      <div class="table-wrapper mt-3 flex flex-column">
        <div class="flex p-3 odd-row top">
          <div class="flex-grow-1 flex align-items-center p-2"><b>Investment Assets (clarity)</b></div>
          <div class="flex-none flex justify-content-center align-items-center p-1" :class="[showMobile === 'mobile' ? 'mobile' : '']">
            <router-link to="/investments/valuation">
              <span class="material-icons-round grey" v-tooltip.top="'View Info'">add</span>
            </router-link>
          </div>
          <div class="flex-none flex justify-content-end align-items-center p-2" style="width: 25%;">
            <b><currency-value :value="clarityAssets" :decimal-places="2" /></b>
          </div>
        </div>
        <div class="flex p-3">
          <div class="flex-grow-1 flex align-items-center p-2"><b>Self Managed Assets</b></div>
          <div class="flex-none flex justify-content-center align-items-center p-1" :class="[showMobile === 'mobile' ? 'mobile' : '']">
            <!-- @todo - go to self managed holdings -->
            <router-link to="/investments/valuation">
              <span class="material-icons-round grey" v-tooltip.top="'View Info'">add</span>
            </router-link>
          </div>
          <div class="flex-none flex justify-content-end align-items-center p-2" style="width: 25%;">
            <b><currency-value :value="selfManaged" :decimal-places="2" /></b>
          </div>
        </div>
        <div class="flex p-3 odd-row">
          <div class="flex-grow-1 flex align-items-center p-2"><b>Bank Accounts & Cards (Cash)</b></div>
          <div class="flex-none flex justify-content-center align-items-center p-1" :class="[showMobile === 'mobile' ? 'mobile' : '']">
            <router-link to="/investments/openbanking">
              <span class="material-icons-round grey" v-tooltip.top="'View Info'">add</span>
            </router-link>
          </div>
          <div class="flex-none flex justify-content-end align-items-center p-2" style="width: 25%;">
            <b><currency-value :value="bank" :decimal-places="2" /></b>
          </div>
        </div>
        <div class="flex p-3 footer-row">
          <div class="flex-grow-1 flex align-items-center p-2">
            <span class="white">
              <b>Total Valuation</b>
            </span>
          </div>
          <div class="flex-none flex justify-content-center align-items-center p-1"></div>
          <div class="flex-none flex justify-content-end align-items-center p-2" style="width: 25%;">
            <span class="white">
              <b><currency-value :value="total" :decimal-places="2" /></b>
            </span>
          </div>
        </div>
      </div>
   </div>
</template>
<script>
import {useStore} from "vuex";
import {computed, onMounted, watch} from "vue";
import CurrencyValue from "../common/CurrencyValue";
import dayjs from "dayjs";
import { loadPresentValues } from '@/core/services/documentExport/ValuationDataExtractor';

export default {
  name: "DashboardValuationTable",
  components: {CurrencyValue},
  setup() {
    const store = useStore();

    const callFilters = computed(() => store.getters.valuationCallFilters)
    const fullPortfolio = computed(() => store.getters.fullPortfolioSelected)
    const policyOptions = computed(() => store.getters.policyOptions)

    const clarityAssets = computed(() => {
     return store.state.dashboard.dashboardClarityAssets;
    })

    const selfManaged = computed(() => {
      return store.state.dashboard.dashboardSelfManaged;
    })

    const bank = computed(() => {
      return store.state.dashboard.dashboardBankAccounts;
    })

    const total = computed( () => {
      return clarityAssets.value+selfManaged.value+bank.value;
    })

    const updateValuationTotals = async () => {

        const fullPortfolio = [];
        for(let groupKey in policyOptions.value){
            const group = policyOptions.value[groupKey]
            for(let itemKey in group.items){
                fullPortfolio.push(group.items[itemKey].value);
            }
        }

        // do something here.
        let clarityTotal = 0;
        let bankTotal = 0;
        let selfManagedTotal = 0;
        await Promise.all(fullPortfolio.map(async (planstring) => {
            const planValue = await loadPresentValues(planstring, dayjs());
            // check if it is self managed or bank account
            if(planstring.startsWith("OB::")){
                bankTotal += planValue
            }else if(planstring.startsWith("SM::")){
                selfManagedTotal += planValue
            }else{
                clarityTotal += planValue
            }
        }));
        store.commit('setDashboardClarityAssets', clarityTotal)
        store.commit('setDashboardBankAccounts', bankTotal)
        store.commit('setDashboardSelfManaged', selfManagedTotal)

    }

    watch(callFilters, () => {
      store.dispatch('getPolicyOptions')
      updateValuationTotals();
    })

    watch(fullPortfolio, () => {
      store.dispatch('getPolicyOptions')
      updateValuationTotals();
    })

    onMounted(async () => {
        updateValuationTotals();
    })

    return {
      clarityAssets,
      selfManaged,
      bank,
      total,
      fullPortfolio,
      showMobile: computed(() => store.getters.getTabView),
    }
  }
}
</script>
<style scoped>
  .table-wrapper {
    /* UI Properties */
    border: 2px solid var(--clarity-pure-white);
    border-radius: 27px;
    opacity: 1;
    box-shadow: 0px 5px 20px #0000000D;
  }

  .odd-row{
    background-color: var(--clarity-pure-white);
  }

  .odd-row.top {
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
  }

  .footer-row {
    background: transparent linear-gradient(180deg, var(--clarity-light-blue) 0%, #7CC2E2 100%) 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
  }
  .mobile {
    display: none !important;
  }
</style>
