<template>
  <div data-cy="dashboard">
    <DashboardValuation />
  </div>
</template>
<script>
import DashboardValuation from "@/components/DashboardValuation.vue";

export default {
  components: {
    DashboardValuation,
  },
}
</script>
