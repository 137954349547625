<template>
  <div>
    <div v-if="services.investment">
      <div class="p-5 flex flex-column">
        <content-card-full>
          <template #title>
            <card-title>
              <template #title><h2>IMS (Investment Management Service)</h2></template>
            </card-title>
          </template>
          <template #content-full>
            <h3>IMS APPROVAL CENTRE</h3>
            <div v-if="rawApprovalRecords">
              <div class="flex align-items-center" id="dash-ims-content">
                <div class="flex flex-column flex-grow-1">
                  <h3 class="orange flex pt-3"><span class="material-icons-outlined pr-3">warning</span>ACTIONS REQUIRED
                  </h3>
                  <p><b>You have {{ rawApprovalRecords }} Rebalances with pending approval.</b> Please visit the
                    approval center to review the application approval records.</p>
                </div>
                <div class="flex" id="btn-div">
                  <router-link to="/ims/imshome" style="width: 100%">
                    <Button class="clarity-btn clarity-gradient-orange mr-5 ims-button justify-content-center">Continue
                      Now
                    </Button>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="no-approvals flex flex-column align-items-center p-5 mt-3" align="center">
              <h3 class="mb-3">NO PENDING APPROVALS</h3>
              <p class="mb-0">There are no IMS applications to approve yet. Please return later.<br>Do you need any
                help? Please contact your Adviser.</p>
            </div>
          </template>
        </content-card-full>
      </div>
    </div>
    <div v-else>
      <div class="p-5 flex flex-column">
        <div class="table-wrapper p-5" v-if="showIMSAdvert">
          <div class="flex flex-column">
            <div class="sales-title mb-5">
              DISCOVER IMS: <br/>OUR INVESTMENT<br/> MANAGEMENT SERVICE
            </div>
            <div class="flex flex-wrap">
              <a href="https://website-public-assets.clarityglobal.com/saleswebsite/pdf/clarityInvestmentManagementBrochure.pdf" target="_blank"><Button class="clarity-btn clarity-gradient-orange mr-5 ims-button justify-content-center">Read More
              </Button></a>
              <Button class="clarity-btn clarity-gradient-grey ims-button justify-content-center" @click="closeAdvert">
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ContentCardFull from "../common/ContentCardFull";
import CardTitle from "../common/CardTitle";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";

export default {
  name: "DashboardIMS",
  components: {CardTitle, ContentCardFull},
  setup() {
    const store = useStore();

    const services = computed(() => store.getters.getClientServices)
    const showIMSAdvert = ref(true);

    const rawApprovalRecords = computed(() => {
      const records = store.getters.getApprovalRecords;
      if (records && records.length > 0) {
        return records.length;
      } else {
        return false;
      }
    });

    // on mount ensure the data feeds call is made!
    onMounted(() => {
      // dispatch the action to load the ims approval records.
      store.dispatch('getIMSApprovalRecords');
    })

    const closeAdvert = () => {
      showIMSAdvert.value = false;
    }

    return {
      rawApprovalRecords,
      services,
      showIMSAdvert,
      closeAdvert
    }
  }
}
</script>
<style scoped lang="scss">
.no-approvals {
  background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--clarity-light-grey);
  border-radius: 27px;
  opacity: 1;
}

.sales-title {
  /* UI Properties */
  color: var(--clarity-dark-grey);
  text-align: left;
  font: normal normal 300 60px/72px Josefin Sans;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  opacity: 1;
}

.ims-button {
  min-width: 150px;
}

.table-wrapper {
  /* UI Properties */
  border: 2px solid var(--clarity-pure-white);
  border-radius: 27px;
  opacity: 1;
  box-shadow: 0px 5px 20px #0000000D;
  background-image: url('../../assets/img/dashboard/advertbackground.png');
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .content-title {
    .filters {
      padding: unset;
    }
  }
  .content-full {
    > h3 {
      padding-left: 20px;
    }
  }
  #dash-ims-content {
    flex-direction: column;
    padding: 0 15px 15px 15px;

    #btn-div {
      align-self: flex-end;

      .clarity-btn {
        padding: 15px 45px;
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .sales-title {
    /* UI Properties */
    color: var(--clarity-dark-grey);
    text-align: left;
    font: normal normal 300 30px/42px Josefin Sans;
    letter-spacing: -0.6px;
    text-transform: uppercase;
    opacity: 1;
  }
}
</style>
