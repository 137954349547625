<template>
  <div>
    <div class="dashboard-valuation" :class="[showFilters ? 'expanded' : '']">
      <div v-if="chartData">
        <DashboardValuationChart :chart-data="chartData"/>
      </div>
      <div class="valuation-summary-filter">
        <div class="flex dash-filter-outer-wrapper">
          <div class="clarity-margin-left-s flex-1 flex align-items-center justify-content-left ">
            <h3 class="blue filter-group-title long">VALUATION SUMMARY</h3>
            <h3 class="blue filter-group-title short">VALUATION INFO</h3>
          </div>
          <!--  Filter group placement for screen med upwards  -->
          <transition name="slide-fade" mode="out-in" class="filter-group-m-up" id="m-up-dashboard-filters">
            <FilterGroup :showOptions="['portfolios', 'people', 'period', 'currency']" toggleKey="dashboardfilter" />
          </transition>
          <div class="flex align-items-center filter-toggle">
            <span class="mr-3 hide-filter-label grey">Filters:</span>
            <Button v-if="showFilters" class="clarity-btn round clarity-gradient-white" @click="toggleFilters"
                    v-tooltip.top="'Close Filters'"><span class="material-icons-round blue">filter_alt</span></Button>
            <Button v-if="!showFilters" class="clarity-btn round clarity-gradient-grey" @click="toggleFilters"
                    v-tooltip.top="'Open Filters'"><span class="material-icons-round grey">filter_alt</span></Button>
          </div>
        </div>
        <!--  Filter group placement for screen up to small  -->
        <transition name="slide-fade" mode="out-in" class="filter-group-s-down" id="s-down-dashboard-filters">
          <FilterGroup :showOptions="['portfolios', 'people', 'period', 'currency']" toggleKey="dashboardfilter" />
        </transition>
      </div>
      <div class="dashboard-valuation-text"
           :class="[showMobile === 'mobile' && showFilters ? 'mobile-val-text' : '', showFilters ? 'expanded' : '']">
        <div class="flex valuation-data-labels">
          <div class="flex-1 flex align-items-center justify-content-center"><h3 class="dark-grey">ASSETS</h3></div>
          <div class="flex-1 flex align-items-center justify-content-center"><h3 class="dark-grey">NET WORTH</h3></div>
          <div class="flex-1 flex align-items-center justify-content-center"><h3 class="dark-grey">LIABILITIES</h3>
          </div>
        </div>
        <div class="flex valuation-data">
          <div class="flex-1 flex align-items-center justify-content-center">
            <h3 class="blue">
                      <span v-if="asset !== 'Error'">
                        <currency-value :value="asset" :decimal-places="0"/>
                      </span>
              <span v-else>Error</span>
            </h3>
          </div>
          <div class="flex-1 flex align-items-center justify-content-center">
            <h3 class="net-worth blue">
              <b>
                      <span v-if="netWorth !== 'Error'">
                        <currency-value :value="netWorth" :decimal-places="0"/>
                      </span>
                <span v-else>Error</span>
              </b>
            </h3>
          </div>
          <div class="flex-1 flex align-items-center justify-content-center">
            <h3 class="blue">
                      <span v-if="liabilities !== 'Error'">
                        <currency-value :value="liabilities" :decimal-places="0"/>
                      </span>
              <span v-else>Error</span>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="grid" :class="[showMobile === 'mobile']">
      <div class="col-12 lg:col-8 pl-5">
        <div class="pr-5">
          <div class="flex align-items-center">
            <div class="flex-grow-1">
              <h3>VALUATION DATE</h3>
            </div>
            <div><span class="small-text">Date:</span> <b>{{ theDate }}</b></div>
          </div>
          <dashboard-valuation-table/>
        </div>
      </div>
      <div class="col-12 lg:col-4">
        <div class="flex-grow-1 pl-5 pr-5">
          <h3>VALUATION REPORT</h3>
          <div class="flex flex-column card-container green-container">
            <div class="mt-3">
              <router-link to="/investments/valuation">
                <Button class="clarity-btn clarity-gradient-orange justify-content-center" style="width: 100%">View the
                  Investment Details
                </Button>
              </router-link>
            </div>
            <div class="mt-5">
              <DownloadButton :items="items" colourClass="clarity-gradient-grey" buttonText="Download Report"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dashboard-i-m-s/>
    <HelpCard mode="dashboard" class="p-5"/>
    <open-banking-content :scrollable="true"/>
    <div class="dashboard-news-newsletter flex flew-wrap">
      <div id="dashboard-newsletter-container" class="flex" v-if="!isSubscribed">
        <dashboard-newsletter/>
      </div>
      <div id="dashboard-news-container" class="flex flex-grow-1" >
        <dashboard-news/>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref, watch} from 'vue'
import DashboardValuationChart from "./charts/DashboardValuationChart";
import CurrencyValue from "./common/CurrencyValue";
import FilterGroup from '@/components/common/filters/FilterGroup'
import DownloadButton from "./common/layout/interactive/DownloadButton";
import OpenBankingContent from "./open_banking/OpenBankingContent";
import DashboardValuationTable from "./dashboard/DashboardValuationTable";
import DashboardNews from "./dashboard/DashboardNews";
import DashboardNewsletter from "./dashboard/DashboardNewsletter";
import DashboardIMS from "./dashboard/DashboardIMS";
import {buildValuationPDFString} from "../core/services/documentExport/valuationPdfBuilder";
import {buildValuationExcelPayload} from "@/core/services/documentExport/valuationExcelBuilder";
import dayjs from "dayjs";
import {downloadDocument} from "../core/services/documentDownload";
import HelpCard from "@/components/common/HelpCard";

export default {
  name: 'DashboardValuation',
  components: {
    HelpCard,
    DashboardIMS,
    DashboardNewsletter,
    DashboardNews,
    DashboardValuationTable,
    OpenBankingContent,
    DownloadButton,
    CurrencyValue,
    DashboardValuationChart,
    FilterGroup
  },
  setup() {
    const store = useStore();

    const theDate = ref(dayjs().format('DD/MM/YYYY'));

    const callFilters = computed(() => store.getters.valuationDateCallFilters)
    const periodCallFilters = computed(() => {
      const filters = store.getters.valuationDateCallFilters;
      filters.period = store.state.selections.periodSelected;
      return filters;
    })

    const asset = computed(() => {
      const assetValue = store.state.dashboard.dashboardAssets;
      if (assetValue && assetValue.error) {
        store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Dashboard Valuations',
          detail: assetValue.message
        })
        return 'Error';
      } else {
        return assetValue;
      }
    })
    const liabilities = computed(() => {
      const liabilitiesValue = store.state.dashboard.dashboardLiabilities;
      if (liabilitiesValue && liabilitiesValue.error) {
        store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Dashboard Valuations',
          detail: liabilitiesValue.message
        })
        return 'Error';
      } else {
        return liabilitiesValue;
      }
    })
    const chartData = computed(() => {
      const theData = store.state.dashboard.dashboardChartData;
      if (theData && theData.error) {
        store.dispatch('addNotification', {
          severity: 'error',
          summary: 'Valuation Summary Chart',
          detail: theData.message
        })
        return 'Error';
      } else {
        return theData;
      }
    })

    const netWorth = computed(() => {
      if (asset.value !== 'Error' && liabilities.value !== 'Error') {
        return asset.value + liabilities.value;
      } else {
        return 'Error';
      }
    })

    watch(callFilters, (value) => {
      getChartTotals(value);
    })

    watch(periodCallFilters, (value) => {
      getChartData(value);
    })

    onMounted(async () => {
      store.dispatch('getPolicyOptions')
      getChartTotals(callFilters.value);
      getChartData(periodCallFilters.value);
      store.dispatch('getNewsletter')
    })

    const getChartTotals = (filters) => {
      store.dispatch('getDashboardChartTotals', filters)
    }

    const getChartData = (filters) => {
      store.dispatch('getDashboardChartData', filters)
    }

    const showFilters = computed(() => store.getters.getElementVisibility('dashboardfilter'));

    const toggleFilters = () => {
        store.dispatch('updateElementVisibility', { "key": 'dashboardfilter', "value": !showFilters.value})
    }

    const portfolio = computed(() => store.getters.portfolioSelected)
    const fullPortfolioSelected = computed(() => store.getters.fullPortfolioSelected)
    const getHumanReadablePortfolioName = () => {
      switch (portfolio.value) {
        case 'nonpens':
          return 'NONPENSION'
        case 'pens':
          return 'PENSION'
        case 'all':
          return 'ALLHOLDINGS'
        default:
          // assumption here is that ISA and IMS will remain the same
          return fullPortfolioSelected.value.name.replace(" ","");
      }
    }

    const downloadPDF = async () => {
      const currency = store.getters.currencySelected;
      const adviser = { name: store.getters.getAdviserName };
      const valuationPdf = await buildValuationPDFString(dayjs(), currency, adviser)

      if (valuationPdf) {
        const fileName = `ValuationReport_${getHumanReadablePortfolioName(portfolio)}_${dayjs().format('DDMMYYYY')}.pdf`
        downloadDocument('pdf', valuationPdf, fileName, 'valuation_pdf')
      }
    }

    const downloadExcel = async () => {
      const valuationExcel = await buildValuationExcelPayload(dayjs())

      if (valuationExcel) {
        const fileName = `ValuationReport_${getHumanReadablePortfolioName()}_${dayjs().format('DDMMYYYY')}.xlsx`
        downloadDocument('excel', valuationExcel, fileName, 'ClientValuation')
      }
    }

    const items = ref([
      {
        key: '0_0',
        label: 'PDF',
        icon: 'pdf-icon',
        command: () => {
          downloadPDF()
          store.dispatch('setLoading', {id: 'valuation_pdf', state: true})
        }
      },
      {
        key: '0_1',
        label: 'Excel',
        icon: 'description',
        command: () => {
          downloadExcel()
          store.dispatch('setLoading', {id: 'ClientValuation', state: true})
        }
      }
    ])

    const valuationTable = computed(() => {
      return [
        {"label": "Investment Assets (clarity)", "value": 123456},
        {"label": "Self Managed Assets", "value": 891011},
        {"label": "Bank Accounts & Cards (Cash)", "value": 121314},
      ]
    })

    return {
      asset,
      liabilities,
      netWorth,
      chartData,
      callFilters,
      toggleFilters,
      items,
      valuationTable,
      theDate,
      showMobile: computed(() => store.getters.getTabView),
      isSubscribed: computed(() => store.getters.getNewsletterSubState),
      showFilters
    }
  }
}
</script>

<style scoped lang="scss">
#dashboard-newsletter-container {
  min-width: 300px;
}

.dashboard-news-container {
  margin-left: 20px;
}

.dashboard-valuation {
  position: relative;
  width: 100%;
  height: 500px;
}

.dashboard-valuation-chart {
  position: relative;
  width: 100%;
  height: 500px;
}

.dashboard-valuation-text {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 150px;
  //background-image: linear-gradient(0deg, rgba(239, 243, 248, 1), rgba(239, 243, 248, 0));
}

.dashboard-valuation-text h3, .dashboard-valuation-text h4 {
  margin: 0;
}

.filter-group-title {
  width: 200px;
}

.valuation-data-labels h3 {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 22px;
}

.valuation-data h3 {
  font-size: 38px;
}

.valuation-summary-filter {
  position: absolute;
  width: 100%;
  top: -10px;
}

.valuation-summary-filter h2 {
  margin-left: 40px;
}

.valuation-summary-filter label {
  margin-right: 4px;
  margin-left: 5px;
}

.valuation-summary-filter h3 {
  font-size: 30px;
  margin-right: 76px;
}

#m-up-dashboard-filters,  {
  background: unset;
}

#m-up-dashboard-filters:deep(h3),
#m-up-dashboard-filters:deep(.filter-close),
#s-down-dashboard-filters:deep(h3),
#s-down-dashboard-filters:deep(.filter-close){
  display: none;
}

#m-up-dashboard-filters:deep(.title-dropdown),
#s-down-dashboard-filters:deep(.title-dropdown){
  min-width: 20%;
}

#m-up-dashboard-filters:deep(.options-wrapper),
#s-down-dashboard-filters:deep(.options-wrapper)
{
  // justify-content: flex-end !important;
}

#m-up-dashboard-filters:deep(.title-dropdown),
#s-down-dashboard-filters:deep(.title-dropdown)
{
  padding: 0px 10px;
}

.filter-toggle {
  padding-top: 10px;
  padding-right: 30px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-leave-active {
  //transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

.dashboard-news-newsletter {
  display: flex !important;
}

#m-up-dashboard-filters {
  display: flex;
}


#s-down-dashboard-filters {
  display:none;
}

.dash-filter-outer-wrapper {
  height: 140px;
}

.filter-group-title {
  &.long {
    display: flex;
  }

  &.short {
    display: none;
  }
}

.expanded {
  padding-top: 0;
}

.two-thirds {
  min-width: 66%;
}

@media only screen and (min-width: 1920px) {
  .valuation-summary-filter h3 {
    font-size: 30px;
    margin-right: 0;
  }
  .filter-group:deep(.options-wrapper) {
    flex-wrap: unset !important;
    width: 75%;
  }
  .filter-group:deep(.title-dropdown) {
    width: 100%;
    padding: 0px 10px;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1920px) {
  .valuation-summary-filter h3 {
    font-size: 22px;
    margin-right: 0;
  }
  .filter-group:deep(.options-wrapper) {
    flex-wrap: unset !important;
    width: 75%;
  }
  .filter-group:deep(.title-dropdown) {
    width: 100%;
    padding: 0px 10px;
  }
}

@media only screen and (min-width: 1367px) {
  .filter-group {
    background: unset;
    flex-direction: row;
    padding: 20px 0;
  }
  .filter-group:deep(.filter-group-title-wrapper) {
    display: none !important;
  }
  .filter-group:deep(.options-wrapper) {
    flex-wrap: unset !important;
    width: 75%;
  }
  .filter-group:deep(.title-dropdown) {
    width: 100%;
    padding: 0px 10px;
  }
  #s-down-dashboard-filters {
    display:none;
  }
}

@media only screen and (max-width: 1366px) {
  .valuation-data-labels h3 {
    font-size: 18px;
  }
  .valuation-data h3 {
    font-size: 22px;
  }
  .net-worth {
    font-size: 30px !important;
  }
  .valuation-summary-filter h3 {
    font-size: 22px;
    margin-right: 0;
    width: 40%;
  }
  .p-dropdown-label {
    font-size: 16px !important;
  }
  #m-up-dashboard-filters {
    display: none;
    background: unset;
  }
  #s-down-dashboard-filters {
    padding: 0 10px;
    display: flex;
    background: unset;
  }
  .dash-filter-outer-wrapper {
    height: 60px;
    margin-top: 10px;
  }
  .filter-group:deep(.options-wrapper) {
    flex-wrap: unset;
    width: 100%;
  }
  .filter-group:deep(.title-dropdown) {
    width: 100%;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .valuation-summary-filter h3 {
    margin-right: 6px;
    width: 60%;
  }
  .hide-filter-label {
    display: none;
  }
  #m-up-dashboard-filters {
    display: none;
  }
  #s-down-dashboard-filters {
    display: flex;
    background: unset;
  }
  .dashboard-valuation-text {
    &.expanded {
      background: unset;
    }
  }
}

@media only screen and (max-width: 980px) {
  .dashboard-news-newsletter {
    display: block !important;
  }
  #dashboard-newsletter-container {
    width: 100%;
  }
  #dashboard-news-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .filter-group-title {
    &.long {
      display: none;
    }

    &.short {
      display: flex;
      width: 80%;
    }
  }
  .expanded {
    margin-top: 250px;
  }
  .filter-group:deep(.options-wrapper) {
    flex-wrap: wrap;
  }
  .filter-group:deep(.title-dropdown) {
    width: 50%;
    padding: 0px 10px;
  }
  .dashboard-valuation-text {
    &.expanded {
      background: unset;
    }

    &.mobile-val-text {
      //z-index: -1;
    }
  }
  .valuation-data-labels > div {
    &:first-of-type, &:nth-of-type(3) {
      display: none !important;
    }
  }
  .valuation-data > div {
    &:first-of-type, &:nth-of-type(3) {
      display: none !important;
    }
  }
  .dashboard-valuation.expanded {
    margin-top: 0px;
    height: 750px;
  }
}

@media only screen and (max-width: 375px) {
  .filter-group:deep(.title-dropdown) {
    width: 100%;
    padding: 0px 10px;
  }
}

</style>
