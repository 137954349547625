<template>
      <div>
            <div v-show="chartRendered" ref="valuationchart" class="dashboard-valuation-chart" :class="[expanded ? 'expanded' : '']"></div>
            <div v-show="!chartRendered" class="dashboard-valuation-loading">
                  <div class="flex align-items-center justify-content-center" style="height: 100%;">
                        <ProgressSpinner style="width:300;height:300px" strokeWidth="3"/>
                  </div>
            </div>
      </div>
</template>

<script>
    import * as am5 from '@amcharts/amcharts5';
    import * as am5xy from '@amcharts/amcharts5/xy';
    import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
    import {ref, watch, toRef, computed} from 'vue'
    import {useStore} from "vuex";
    import dayjs from "dayjs";
    import {formatter} from "../../core/services/formatter";

    export default {
      name: 'DashboardValuationChart',
      props: {
        chartData: Object
      },
      setup(props) {
        am5.addLicense("AM5C334338446");

        const series = ref(null);
        const startLabel = ref();
        const endLabel = ref();
        const chartRendered = ref(false);
        const valuationchart = ref(null);

        const store = useStore();

        const chartData = toRef(props, 'chartData')

        const startText = computed(() => {
            if(chartData.value.length) {
                  const data = new dayjs(chartData.value[0].formatted);
                  return data.format('MMMM YYYY')
            }
            return '';
        })
        const endText = computed(() => {
              if(chartData.value.length) {
                    const data = new dayjs(chartData.value[chartData.value.length - 1].formatted);
                    return data.format('MMMM YYYY')
              }
              return '';
        })

        const selectedOption = computed(() => store.getters.currencySelected );
        const currencyConvertData = (data) => {
          const convertedData = [];
          data.forEach((record) =>{
            convertedData.push({
              "date": record.date,
              "formatted": record.formatted,
              "value": formatter.currency(selectedOption.value, record.value, 2, true)
            })
          })
          return convertedData;
        }

        watch(chartData, (value) => {
          const converted = currencyConvertData(value);
          if(chartRendered.value){
            updateChart(series, converted)
          }else{
            renderChart(series, converted)
            chartRendered.value = true;
          }
        })

        watch(selectedOption, () => {
          const converted = currencyConvertData(chartData.value);
          if(chartRendered.value){
            updateChart(series, converted)
          }else{
            renderChart(series, converted)
            chartRendered.value = true;
          }
        })

        const renderChart = (series, data) => {

          let root = am5.Root.new(valuationchart.value);
          root.setThemes([am5themes_Animated.new(root)]);

          root.numberFormatter.setAll({
            numberFormat: "#,###.00a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
              { "number": 1e+6, "suffix": "M" },
              { "number": 1e+9, "suffix": "B" }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
          });

          let dotGradient = am5.LinearGradient.new(root, {
            stops: [{
              color: am5.color("#179BD7")
            }, {
              color: am5.color("#0058A9")
            }]
          });


          let chart = root.container.children.push(am5xy.XYChart.new(root, {
            width: am5.percent(100),
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0
          }));

          let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            getLabelFillFromSprite: false,
            pointerOrientation: "horizontal",
            labelText: "{valueY}[/]\n{valueX.formatDate('dd-MM-yyyy')}"
          });

          tooltip.get("background").setAll({
            fillGradient: dotGradient
          });

          tooltip.label.setAll({
            fill:  am5.color("#FFFFFF")
          });

          let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 50,
              inside: true,
            }),
            maxDeviation: 0.5,
            baseInterval: {
              timeUnit: "day",
              count: 1
            },
            gridInterval: [
              {timeUnit: "day", count: 1},
            ],
            inside: true,
            visible: false
          }));

          let xRenderer = xAxis.get("renderer");
          xRenderer.labels.template.setAll({
            fill: am5.color("#282B2F"),
            inside: true
          });

          xRenderer.grid.template.setAll({
            location: 0.5,
            stroke: am5.color("#DCE1E6"),
            strokeWidth: 1,
            strokeOpacity: 1
          });

          let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation:1,
            //spacing at the top of the chart
            extraMax: 0.4,
            // spacing at the bottom of the chart
            extraMin: 0.5,
            renderer: am5xy.AxisRendererY.new(root, {
              minGridDistance: 80,
              inside: true,
              opposite: true
            })
          }));

          let yRenderer = yAxis.get("renderer");
          yRenderer.labels.template.setAll({
            fill: am5.color("#282B2F"),
            minPosition: 0.1,
            maxPosition: 0.9
          });

          yRenderer.grid.template.setAll({
            stroke: am5.color("#DCE1E6"),
            strokeWidth: 1,
            strokeOpacity: 1
          });

          series.value = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            locationX: 0.5,
            locationY: 0,
            connect: true,
            tooltip: tooltip
          }));
          //#0058A9
          //#7CC2E2
          //#179BD7

          series.value.fills.template.setAll({
            visible: true,
            fillGradient: am5.LinearGradient.new(root, {
              stops: [{
                color: am5.color("#179BD7"),
                opacity: 1
              },
                {
                  color: am5.color("#7CC2E2"),
                  opacity: 0
                }]
            }),
            fillOpacity: 0.7
          });

          series.value.strokes.template.setAll({
            strokeWidth: 6,
            strokeGradient: am5.LinearGradient.new(root, {
              stops: [{
                color: am5.color("#179BD7")
              }, {
                color: am5.color("#0058A9")
              }]
            })
          });

          series.value.bullets.push(function() {
            return am5.Bullet.new(root, {
              locationY: 0,
              locationX: 0.5,
              sprite: am5.Circle.new(root, {
                radius: 9,
                stroke: root.interfaceColors.get("background"),
                strokeWidth: 3,
                fillGradient: dotGradient
              })
            });
          });


          // Add label
          startLabel.value = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: 8,
            y: am5.percent(92),
            fill: am5.color("#282B2F"),
            fontSize: 11,
            text: startText.value
          }));

          endLabel.value = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.percent(100),
            centerX: 100,
            y: am5.percent(92),
            fill: am5.color("#282B2F"),
            fontSize: 11,
            text: endText.value
          }));

          series.value.data.setAll(data);

          series.value.appear(1000);
          chart.appear(1000, 100);

          // Add cursor
          // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
          var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            snapToSeries: [ series.value ],
          }));
          cursor.lineY.set("visible", false);
          cursor.lineX.set("visible", false);

        }

        const updateChart = (series, data) => {
          series.value.data.setAll(data);
          startLabel.value.set("text", startText.value);
          endLabel.value.set("text", endText.value);
        }

        return {
          series,
          chartRendered,
          valuationchart,
          expanded: computed(() => store.getters.getElementVisibility('dashboardfilter'))
        }
      }
    }
</script>

<style scoped lang="scss">
    .dashboard-valuation-chart {
      position: relative;
      width: 100%;
      height: 500px;
    }
    .dashboard-valuation-loading {
          position: relative;
          width: 100%;
          height: 500px;
    }
    .expanded {
      padding-top: 0;
    }
    @media only screen and (max-width: 768px) {
      .expanded {
        padding-top: 250px;
      }
    }
</style>
