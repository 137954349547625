<template>
  <div class="p-5">
    <div class="table-wrapper flex flex-column p-3 pt-5 pb-5">
      <div class="flex">
        <div class="flex">
          <h3 class="white">SUBSCRIBE<br>TO CLARITY<br>NEWSLETTER</h3>
        </div>
        <div class="flex p-4" style="max-width: 150px;">
          <img src="@/assets/img/dashboard/Vector.svg" alt="news">
        </div>
      </div>
      <div class="flex flex-grow-1 pt-3 align-items-center">
        <span class="body-text white">Keep up to date with our buy list changes, market round ups and what clarity gets up to throughout the year.</span>
      </div>
      <div class="flex pt-3">
        <SubmitButton class="full-width" @click="subscribe" :loading="loadingState" button-text="Subscribe Now" loading-text="Signing up..."></SubmitButton>
      </div>
    </div>
  </div>
</template>
<script>

import {useStore} from "vuex";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";
import {computed} from "vue";

export default {
  name: "DashboardNewsletter",
  components: {SubmitButton},
  setup() {
    const store = useStore()
    const subscribe = () => {
      store.dispatch('setLoading', {id: 'newsletterSignup', state: true})
      setTimeout(() => store.dispatch('subscribeNewsletter'), 3000)

    }
    const loadingState = computed(() => store.getters.isLoading('newsletterSignup'))

    return {
      subscribe,
      loadingState
    }
  }
}
</script>
<style scoped>
.table-wrapper {
  /* UI Properties */
  border: 2px solid var(--clarity-pure-white);
  border-radius: 27px;
  opacity: 1;
  box-shadow: 0 5px 20px #0000000D;
  background: transparent linear-gradient(180deg, #7CC2E2 0%, var(--clarity-light-blue) 100%) 0 0 no-repeat padding-box;
  background-image: url('../../assets/img/dashboard/Background.png');
  background-size: cover;
  background-position: center;
}
.body-text.white {
  color: var(--clarity-pure-white)
}

@media only screen and (max-width: 1024px)  {
  h2 {
    font-size: var(--clarity-font-size-22);
  }
  .body-text.white {
    font-size: var(--clarity-font-size-16);
  }
}
</style>
